var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { staticClass: "relative inline-flex shadow-sm" },
    [
      _c(
        _vm.is,
        _vm._b(
          {
            ref: "button",
            tag: "component",
            class: [
              _vm.baseClasses,
              _vm.colorClasses,
              _vm.sizeClasses,
              _vm.disabled || _vm.loading
                ? "pointer-events-none cursor-default opacity-75"
                : "",
            ],
            attrs: {
              type: _vm.type,
              to: _vm.to,
              role: _vm.to ? "link" : "button",
              disabled: _vm.disabled || _vm.loading,
            },
            on: {
              click: function ($event) {
                return _vm.$emit("click")
              },
            },
          },
          "component",
          _vm.$attrs,
          false
        ),
        [
          _vm._t("default"),
          _c("spinner-overlay", {
            attrs: { color: "light", loading: _vm.loading, size: "md" },
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }