var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { staticClass: "inline-flex relative" },
    [
      _c(
        _vm.is,
        {
          ref: "button",
          tag: "component",
          class: [
            _vm.baseClasses,
            _vm.colorClasses,
            _vm.sizeClasses,
            _vm.disabled || _vm.loading ? "pointer-events-none opacity-75" : "",
          ],
          attrs: {
            type: _vm.type,
            to: _vm.to,
            role: _vm.to ? "link" : "button",
            disabled: _vm.disabled || _vm.loading,
          },
          on: {
            click: function ($event) {
              return _vm.$emit("click")
            },
          },
        },
        [
          _vm._t("default"),
          _c("spinner-overlay", {
            attrs: { color: "light", loading: _vm.loading, size: "lg" },
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }