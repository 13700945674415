var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex w-full flex-col overflow-auto" }, [
    _c(
      "div",
      { staticClass: "flex justify-between pb-4" },
      [_vm._t("tableHeader")],
      2
    ),
    _c("div", { staticClass: "inline-block min-w-full py-2 align-middle" }, [
      _c(
        "div",
        { staticClass: "divide-y-2 divide-gray-400 border bg-gray-50 shadow" },
        [
          _c("div", { staticClass: "overflow-auto" }, [
            _c(
              "table",
              { staticClass: "relative min-w-full divide-y divide-gray-200" },
              [
                _c("thead", {}, [
                  _c(
                    "tr",
                    [
                      _vm._l(_vm.theads, function (thead, i) {
                        return [
                          _vm.hideHeaderColumn(i)
                            ? _c(
                                "th",
                                {
                                  key: i,
                                  staticClass:
                                    "bg-gray-50 px-2 py-3 text-left text-sm font-semibold leading-4 text-gray-800 sm:px-6 lg:px-8",
                                },
                                [_vm._v(" " + _vm._s(thead) + " ")]
                              )
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  ),
                ]),
                _c(
                  "tbody",
                  { staticClass: "divide-y divide-gray-200 bg-white" },
                  [
                    _vm.loading
                      ? _c(
                          "div",
                          { staticClass: "flex flex-col justify-center py-12" },
                          [_vm._t("tableLoading")],
                          2
                        )
                      : _vm._t("default"),
                  ],
                  2
                ),
              ]
            ),
          ]),
          _c(
            "div",
            { staticClass: "flex py-2 px-2 sm:px-6 lg:px-8" },
            [_vm._t("tableFooter")],
            2
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }