var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-modal",
    {
      attrs: { show: _vm.show, "show-close-button": false },
      on: {
        close: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _c(
        "h2",
        {
          staticClass: "mb-4 pb-2 border-b text-xl font-black",
          attrs: { id: "twoFactorTitle" },
        },
        [_vm._v(" Two factor authentication ")]
      ),
      _c("transition", { attrs: { name: "fade" } }, [
        !_vm.showCodeField
          ? _c(
              "form",
              {
                staticClass: "flex flex-wrap py-2",
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.submitTwoFactor($event)
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "w-full flex space-x-2 items-center" },
                  [
                    _c("h4", {}, [_vm._v(" The code will be sent to ")]),
                    _vm.countryCodeList.length
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "font-bold p-2  bg-gray-100 border rounded",
                          },
                          [
                            _vm._v(
                              " +" +
                                _vm._s(
                                  _vm.countryCodeList.find(function (code) {
                                    return code.id == _vm.countryCode
                                  }).code
                                ) +
                                " " +
                                _vm._s(_vm.phoneNumber) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "flex justify-end space-x-2 w-full mt-4" },
                  [
                    _c(
                      "base-button",
                      {
                        attrs: { color: "cancel", type: "button" },
                        nativeOn: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.$emit("close")
                          },
                        },
                      },
                      [_vm._v(" Cancel ")]
                    ),
                    _c(
                      "base-button",
                      {
                        attrs: {
                          focus: "",
                          type: "submit",
                          color: "primary",
                          loading: _vm.loading,
                        },
                      },
                      [_vm._v(" Send code ")]
                    ),
                  ],
                  1
                ),
              ]
            )
          : _c(
              "form",
              {
                staticClass: "flex flex-wrap py-2",
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.submitCode($event)
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "flex w-full justify-between" },
                  [
                    _c(
                      "base-label",
                      { staticClass: "flex-1", attrs: { for: "code" } },
                      [
                        _vm._v(
                          " Enter the 6-digit code sent to your phone number. "
                        ),
                      ]
                    ),
                    _c(
                      "text-button",
                      {
                        staticClass: "mb-2",
                        attrs: { disabled: _vm.loading, type: "button" },
                        nativeOn: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.submitTwoFactor($event)
                          },
                        },
                      },
                      [_vm._v(" Re-send code ")]
                    ),
                  ],
                  1
                ),
                _c("base-input", {
                  directives: [{ name: "focus", rawName: "v-focus" }],
                  attrs: { id: "code", placeholder: "Enter code" },
                  model: {
                    value: _vm.code,
                    callback: function ($$v) {
                      _vm.code = $$v
                    },
                    expression: "code",
                  },
                }),
                _vm.authError
                  ? _c("p", { staticClass: "text-red mt-2" }, [
                      _vm._v(" " + _vm._s(_vm.authError) + " "),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "flex justify-end space-x-2 w-full mt-4" },
                  [
                    _c(
                      "base-button",
                      {
                        attrs: { color: "cancel", type: "button" },
                        nativeOn: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.$emit("close")
                          },
                        },
                      },
                      [_vm._v(" Cancel ")]
                    ),
                    _c(
                      "base-button",
                      {
                        attrs: {
                          type: "submit",
                          color: "primary",
                          loading: _vm.loading,
                        },
                      },
                      [_vm._v(" Submit ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }