var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "label",
    {
      staticClass: "font-semibold select-none",
      class: [_vm.colorClassesString, _vm.sizeClassesString],
    },
    [_vm._t("default", [_vm._v("Label")])],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }