import { render, staticRenderFns } from "./textarea-input.vue?vue&type=template&id=d917c60a"
import script from "./textarea-input.vue?vue&type=script&lang=js"
export * from "./textarea-input.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/firstvet/vetpanel/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d917c60a')) {
      api.createRecord('d917c60a', component.options)
    } else {
      api.reload('d917c60a', component.options)
    }
    module.hot.accept("./textarea-input.vue?vue&type=template&id=d917c60a", function () {
      api.rerender('d917c60a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/UI/inputs/textarea-input.vue"
export default component.exports