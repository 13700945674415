var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("textarea", {
    staticClass:
      "border border-gray-300 rounded w-full p-4 text-gray-900 leading-7\n   transition ease-in-out duration-150 \n  focus:outline-none focus:ring-1 focus:ring-beige",
    attrs: { rows: _vm.rows, placeholder: _vm.placeholder },
    domProps: { value: _vm.value },
    on: {
      input: function ($event) {
        return _vm.$emit("input", $event.target.value)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }