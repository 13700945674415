var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.type,
    {
      tag: "component",
      class: [
        _vm.baseClasses,
        _vm.colorClasses,
        _vm.disabled ? "pointer-events-none opacity-75" : "",
      ],
      attrs: { to: _vm.to, exact: _vm.exact, disabled: _vm.disabled },
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }