var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("fv-icon", {
    staticClass: "animate-spin object-contain align-middle",
    attrs: { icon: _vm.icon, size: _vm.size },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }