var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("inline-svg", {
    class: [_vm.sizeClasses],
    attrs: {
      src: require("@/assets/svg/pictograms/" + _vm.fileName + ".svg"),
      "aria-label": _vm.fileName,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }