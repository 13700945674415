var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "form",
        {
          staticClass: "flex flex-col space-y-2 px-8 py-8",
          attrs: { id: _vm.platform + "LoginForm" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submit($event)
            },
          },
        },
        [
          _vm.errorMsg
            ? _c("p", { staticClass: "text-red-800" }, [
                _vm._v(" " + _vm._s(_vm.errorMsg) + " "),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "flex flex-col space-y-2" },
            [
              _c("base-label", { attrs: { for: "email" } }, [
                _vm._v(" Email "),
              ]),
              _c("base-input", {
                directives: [{ name: "focus", rawName: "v-focus" }],
                attrs: {
                  id: "email",
                  "data-testid": "email-input",
                  type: "email",
                  placeholder: "Enter your email",
                  required: "",
                },
                model: {
                  value: _vm.email,
                  callback: function ($$v) {
                    _vm.email = $$v
                  },
                  expression: "email",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "flex flex-col space-y-2" },
            [
              _c("base-label", { attrs: { for: "password" } }, [
                _vm._v(" Password "),
              ]),
              _c("base-input", {
                attrs: {
                  id: "password",
                  type: "password",
                  "data-testid": "password-input",
                  placeholder: "***********",
                  required: "",
                },
                model: {
                  value: _vm.userPassword,
                  callback: function ($$v) {
                    _vm.userPassword = $$v
                  },
                  expression: "userPassword",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "base-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticClass: "mt-2 w-full",
                  attrs: {
                    id: "loginButton",
                    "data-testid": "login-button",
                    color: "primary",
                    loading: _vm.loading,
                    disabled: _vm.loading,
                  },
                },
                [_vm._v(" Sign in ")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "mb-6 w-full text-center" },
        [
          _c(
            "text-button",
            {
              staticClass: "font-semibold",
              nativeOn: {
                click: function ($event) {
                  $event.preventDefault()
                  _vm.forgotPassword = true
                },
              },
            },
            [_vm._v(" Forgot your password? ")]
          ),
        ],
        1
      ),
      _c(
        "base-modal",
        {
          attrs: { show: _vm.forgotPassword, "show-close-button": false },
          on: {
            close: function ($event) {
              _vm.forgotPassword = false
            },
          },
        },
        [
          _c("forgot-password-form", {
            attrs: { email: _vm.email },
            on: {
              cancel: function ($event) {
                _vm.forgotPassword = false
              },
            },
          }),
        ],
        1
      ),
      _c("two-factor-modal", {
        attrs: {
          show: _vm.showTwoFactorModal,
          "phone-number": _vm.phoneNumber,
          "country-code": _vm.countryCode,
          "temp-token": _vm.tempToken,
        },
        on: {
          close: function ($event) {
            _vm.showTwoFactorModal = false
          },
          keyup: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
            ) {
              return null
            }
            _vm.showTwoFactorModal = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }