var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "h-screen max-h-screen w-full overflow-auto bg-beigeLighter font-sans text-gray-900",
      attrs: { id: "app" },
    },
    [
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [_c("notification-primary")],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [_c("notification-error")],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [_c("notification-auth")],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [_c("notification-booking")],
        1
      ),
      _vm.user || _vm.isGuestRoute
        ? _c("router-view")
        : _c(
            "div",
            {
              staticClass:
                "relative flex h-full w-full flex-col items-center justify-center bg-beigeLighter",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "mx-auto my-auto flex flex-col space-y-4 text-center text-gray-700",
                },
                [
                  _c("img", {
                    staticClass: "mx-auto my-auto h-64 w-56",
                    attrs: { src: require("@/assets/svg/loading_relax.svg") },
                  }),
                  _c(
                    "p",
                    {
                      staticClass:
                        "mx-auto mt-4 inline-flex space-x-2 font-semibold",
                    },
                    [
                      _c("span", { staticClass: "font-display text-lg" }, [
                        _vm._v("Initializing.."),
                      ]),
                      _c(
                        "span",
                        [
                          _c("base-spinner", {
                            attrs: { loading: _vm.loading, size: "lg" },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
            ]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }