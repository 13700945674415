var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex p-8" }, [
      _c("h1", { staticClass: "text-center p-8" }, [
        _vm._v(" 404"),
        _c("br"),
        _vm._v("Page not found "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }