var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-full" },
    [
      _c(
        "input",
        _vm._b(
          {
            staticClass:
              "focus:outline-none w-full rounded border-gray-400 border-opacity-50 py-2 px-2 leading-tight focus:border-gray-600",
            class: _vm.border ? "border" : "border-0",
            attrs: {
              id: _vm.inputId,
              placeholder: _vm.placeholder,
              disabled: _vm.disabled,
              type: _vm.type,
              name: _vm.name,
              "data-testid": _vm.inputId,
            },
            domProps: { value: _vm.value },
            on: {
              input: function ($event) {
                return _vm.$emit("input", $event.target.value)
              },
              focus: function ($event) {
                return _vm.$emit("focus")
              },
              blur: function ($event) {
                return _vm.$emit("blur")
              },
            },
          },
          "input",
          _vm.$attrs,
          false
        )
      ),
      _c("spinner-overlay", {
        attrs: { color: "light", loading: _vm.loading },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }