import { render, staticRenderFns } from "./grid-display-row.vue?vue&type=template&id=77dfe6de&scoped=true"
import script from "./grid-display-row.vue?vue&type=script&lang=js"
export * from "./grid-display-row.vue?vue&type=script&lang=js"
import style0 from "./grid-display-row.vue?vue&type=style&index=0&id=77dfe6de&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77dfe6de",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/firstvet/vetpanel/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('77dfe6de')) {
      api.createRecord('77dfe6de', component.options)
    } else {
      api.reload('77dfe6de', component.options)
    }
    module.hot.accept("./grid-display-row.vue?vue&type=template&id=77dfe6de&scoped=true", function () {
      api.rerender('77dfe6de', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/UI/display-rows/grid-display-row.vue"
export default component.exports