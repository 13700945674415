var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "div",
        {
          staticClass:
            "fixed inset-0 z-50 flex items-center justify-center px-4 pb-4",
          on: {
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              return _vm.$emit("close")
            },
          },
        },
        [
          _vm.show
            ? _c(
                "div",
                {
                  staticClass:
                    "top fixed inset-0 z-20 transition-opacity ease-in-out",
                },
                [
                  _c("div", {
                    staticClass: "absolute inset-0 bg-fv-black opacity-10",
                    on: { click: _vm.close },
                  }),
                ]
              )
            : _vm._e(),
          _vm.show
            ? _c(
                "div",
                {
                  staticClass:
                    "relative z-50 flex w-full transform flex-col justify-center rounded-lg bg-white shadow-xl transition-all",
                  class: [_vm.width],
                  attrs: {
                    role: "dialog",
                    "aria-modal": "true",
                    "aria-labelledby": "modal-headline",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "flex flex-col overflow-hidden",
                      staticStyle: { "max-height": "85vh" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "overflow-auto p-4",
                          attrs: { id: "baseModalWrapper", tabindex: "0" },
                        },
                        [_vm._t("default")],
                        2
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.$slots.buttons,
                          expression: "$slots.buttons",
                        },
                      ],
                      staticClass:
                        "flex w-full justify-end space-x-2 rounded-b-lg border-t bg-beigeLighter px-4 py-2 sm:px-6",
                    },
                    [
                      _vm._t("buttons", [
                        _vm.showCloseButton
                          ? _c(
                              "base-button",
                              {
                                attrs: { color: "cancel", type: "button" },
                                on: { click: _vm.close },
                              },
                              [_vm._v(" Close ")]
                            )
                          : _vm._e(),
                      ]),
                    ],
                    2
                  ),
                ]
              )
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }