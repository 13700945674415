var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("input", {
    staticClass: "form-switch-checkbox",
    attrs: { id: "1", type: "checkbox", name: "1" },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }