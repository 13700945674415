var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "relative" }, [
    _c(
      "select",
      {
        staticClass:
          "w-full bg-smoke-lightest appearance-none px-2 py-2 rounded border border-transparent\n    cursor-pointer hover:text-black hover:border-gray-700 focus:outline-none focus:ring",
        on: {
          change: function ($event) {
            return _vm.$emit("change", $event.target.value)
          },
        },
      },
      _vm._l(_vm.options, function (option) {
        return _c(
          "option",
          {
            key: option.id,
            domProps: {
              selected: _vm.value === option[_vm.valueAttribute] ? true : false,
              value: option[_vm.valueAttribute],
            },
          },
          [_vm._v(" " + _vm._s(option[_vm.labelAttribute]) + " ")]
        )
      }),
      0
    ),
    _c(
      "div",
      {
        staticClass:
          "absolute top-0 bottom-0 right-0 flex flex-col justify-center items-center mr-2",
      },
      [
        _c("font-awesome-icon", {
          staticClass: "text-xs text-ou-dark",
          attrs: { icon: "caret-down" },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }