var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex-row space-x-1 pt-1 pb-0.5" },
    [
      _vm.labelText.length
        ? _c("base-label", { attrs: { color: "dark" } }, [
            _vm._v(" " + _vm._s(_vm.labelText) + " "),
          ])
        : _vm._e(),
      _c(
        "span",
        { staticClass: "text-navy" },
        [_vm._v(" " + _vm._s(_vm.displayValue) + " "), _vm._t("default")],
        2
      ),
      _vm.displayValue && _vm.hasCopyButton
        ? _c(
            "text-button",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: "Copy to clipboard",
                  expression: "'Copy to clipboard'",
                },
              ],
              staticClass: "ml-1",
              attrs: { id: "copyButton", size: "sm" },
              on: {
                click: function ($event) {
                  return _vm.copyValueToClipboard()
                },
              },
            },
            [_c("fv-icon", { attrs: { icon: "copy", size: "xs" } })],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }