var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("notifications", {
    attrs: { group: "booking", width: "400px", position: "top right" },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function (props) {
          return [
            _c(
              "div",
              {
                staticClass:
                  "my-4 ml-2 rounded-l-lg border-t-4 border-primary bg-beigeLighter p-4 shadow",
                attrs: { role: "alert" },
              },
              [
                _c(
                  "div",
                  { staticClass: "inline-flex items-center space-x-4" },
                  [
                    _c("fv-icon", { attrs: { icon: "calendar" } }),
                    _c("div", { staticClass: "flex flex-col space-y-1" }, [
                      _c("span", { staticClass: "font-semibold" }, [
                        _vm._v(_vm._s(props.item.title)),
                      ]),
                      _c("span", {
                        domProps: { innerHTML: _vm._s(props.item.text) },
                      }),
                    ]),
                  ],
                  1
                ),
              ]
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }