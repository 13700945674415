var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "bg-white bg-opacity-75 rounded shadow relative",
      class: { "p-4": _vm.usePadding },
    },
    [
      _c("spinner-overlay", {
        attrs: { size: "xl", color: "light", loading: _vm.loading },
      }),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }