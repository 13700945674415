var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex justify-center items-center h-full" },
    [
      _c(
        "div",
        { staticClass: "w-full lg:w-2/5" },
        [
          _c(
            "card",
            { staticClass: "mx-2 justify-center mb-0" },
            [
              _c("div", { staticClass: "flex flex-col py-4 space-x-6" }, [
                _c("div", { staticClass: "w-full pb-2 text-center" }, [
                  _c("img", {
                    staticClass: "w-auto h-12 mx-auto",
                    attrs: { src: require("@/assets/svg/firstvet_logo.svg") },
                  }),
                ]),
              ]),
              _c("ValidationObserver", {
                ref: "observer",
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var handleSubmit = ref.handleSubmit
                      return [
                        !_vm.passwordIsSet
                          ? _c(
                              "form",
                              {
                                staticClass:
                                  "flex flex-wrap justify-center mx-8",
                                on: {
                                  submit: function ($event) {
                                    $event.preventDefault()
                                    return handleSubmit(_vm.submit)
                                  },
                                },
                              },
                              [
                                !_vm.passwordIsSet
                                  ? _c(
                                      "p",
                                      {
                                        staticClass:
                                          "font-semibold text-left text-lg mt-4 pb-2 border-b w-full mb-6",
                                      },
                                      [_vm._v(" Set your new password ")]
                                    )
                                  : _vm._e(),
                                _c("ValidationProvider", {
                                  staticClass: "w-full mb-4",
                                  attrs: {
                                    rules: "required|min:8",
                                    name: "password",
                                    vid: "password",
                                    tag: "div",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var errors = ref.errors
                                          return [
                                            _c(
                                              "base-label",
                                              { attrs: { for: "password" } },
                                              [_vm._v(" Password ")]
                                            ),
                                            _c("base-input", {
                                              attrs: {
                                                id: "password",
                                                type: "password",
                                                placeholder: "***********",
                                                name: "password",
                                              },
                                              model: {
                                                value: _vm.password,
                                                callback: function ($$v) {
                                                  _vm.password = $$v
                                                },
                                                expression: "password",
                                              },
                                            }),
                                            errors.length
                                              ? _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "text-red-800 mt-2",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(errors[0]) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _c("ValidationProvider", {
                                  staticClass: "w-full mb-4",
                                  attrs: {
                                    rules: { required: true, is: _vm.password },
                                    name: "passwordConfirmation",
                                    vid: "passwordConfirmation",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var errors = ref.errors
                                          return [
                                            _vm.password.length > 7
                                              ? _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      "base-label",
                                                      {
                                                        attrs: {
                                                          for: "passwordConfirmation",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Confirm password "
                                                        ),
                                                      ]
                                                    ),
                                                    _c("base-input", {
                                                      attrs: {
                                                        id: "passwordConfirmation",
                                                        type: "password",
                                                        placeholder:
                                                          "***********",
                                                        name: "passwordConfirmation",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.passwordConfirmation,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.passwordConfirmation =
                                                            $$v
                                                        },
                                                        expression:
                                                          "passwordConfirmation",
                                                      },
                                                    }),
                                                    errors.length
                                                      ? _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "text-red-800 mt-2",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  errors[0]
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm.error
                                  ? _c("p", { staticClass: "text-red-700" }, [
                                      _vm._v(" " + _vm._s(_vm.error) + " "),
                                    ])
                                  : _vm._e(),
                                _c(
                                  "base-button",
                                  {
                                    staticClass: "w-full mb-6 text-center",
                                    attrs: {
                                      loading: _vm.loading,
                                      color: "primary",
                                      disabled: _vm.loading,
                                    },
                                  },
                                  [_vm._v(" Submit ")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm.passwordIsSet
                ? _c(
                    "div",
                    {
                      staticClass: "flex flex-col justify-center items-center",
                    },
                    [
                      _c("fv-icon", {
                        staticClass: "mr-3 text-fv-green mb-2",
                        attrs: { icon: "check-mark", size: "3x" },
                      }),
                      _c(
                        "p",
                        {
                          staticClass:
                            "font-semibold text-xl mt-2 mb-6 text-center",
                        },
                        [_vm._v(" Your new password has been set! ")]
                      ),
                      _c(
                        "base-button",
                        {
                          staticClass: "w-full mb-6 mt-6 text-center",
                          attrs: { loading: _vm.loading, color: "primary" },
                          on: {
                            click: function ($event) {
                              _vm.showTwoFactorModal = true
                            },
                          },
                        },
                        [_vm._v(" Sign in ")]
                      ),
                      _c("div"),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("two-factor-modal", {
        attrs: {
          show: _vm.showTwoFactorModal,
          "phone-number": _vm.phoneNumber,
          "country-code": _vm.countryCode,
          "temp-token": _vm.tempToken,
        },
        on: {
          close: function ($event) {
            _vm.showTwoFactorModal = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }