var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "select",
    {
      class: [
        _vm.baseClasses,
        _vm.borderClasses,
        _vm.focusClasses,
        _vm.hoverClasses,
      ],
      attrs: { disabled: _vm.disabled },
      domProps: { value: _vm.value },
      on: {
        change: function ($event) {
          return _vm.$emit("change", $event.target.value)
        },
      },
    },
    [
      _vm.placeholder
        ? _c(
            "option",
            {
              staticClass: "text-gray-200",
              attrs: { value: "", disabled: "", selected: "" },
            },
            [_vm._v(" " + _vm._s(_vm.placeholder) + " ")]
          )
        : _vm._e(),
      _vm._l(_vm.options, function (opt, i) {
        return _c(
          "option",
          {
            key: i,
            domProps: {
              value: opt[_vm.valueAttr],
              selected: opt.id === _vm.value,
            },
          },
          [_vm._v(" " + _vm._s(opt[_vm.labelAttr]) + " ")]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }