var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.errorMsg
        ? _c("p", { staticClass: "text-center text-red-800" }, [
            _vm._v(" " + _vm._s(_vm.errorMsg) + " "),
          ])
        : _vm._e(),
      _vm.legacyLogin
        ? _c("email-password-login")
        : _c(
            "base-button",
            {
              staticClass: "my-4 w-full",
              attrs: {
                color: "primary",
                to: _vm.firstvetAuthUrl,
                href: _vm.firstvetAuthUrl,
                "data-testid": "login-button",
              },
            },
            [_vm._v(" Sign in ")]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }