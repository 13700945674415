var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Fragment",
    [
      _vm.labelText.length
        ? _c("base-label", { attrs: { color: "light" } }, [
            _vm._v(" " + _vm._s(_vm.labelText) + " "),
          ])
        : _vm._e(),
      _c(
        "div",
        [
          _c(
            "span",
            {
              staticClass: "font-bold text-gray-900",
              attrs: { "data-testid": _vm.testid },
            },
            [_vm._v(" " + _vm._s(_vm.displayValue) + " "), _vm._t("default")],
            2
          ),
          _vm.displayValue && _vm.hasCopyButton
            ? _c(
                "text-button",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: "Copy to clipboard",
                      expression: "'Copy to clipboard'",
                    },
                  ],
                  staticClass: "ml-1",
                  attrs: { id: "copyButton", size: "sm" },
                  on: {
                    click: function ($event) {
                      return _vm.copyValueToClipboard()
                    },
                  },
                },
                [_c("fv-icon", { attrs: { icon: "copy", size: "xs" } })],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }