var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-full flex flex-col space-y-1" }, [
    _c(
      "div",
      [
        _c("base-label", {}, [
          _vm._v(" " + _vm._s(_vm.title) + " "),
          _vm.required
            ? _c("span", { staticClass: "text-red-light" }, [_vm._v("*")])
            : _vm._e(),
        ]),
        _vm.error
          ? _c(
              "span",
              { staticClass: "text-red-800 font-semibold text-xs ml-6" },
              [_vm._v(" Please select an option. ")]
            )
          : _vm._e(),
      ],
      1
    ),
    _c("div", { staticClass: "flex items-center mb-2 space-x-4" }, [
      _c(
        "label",
        {
          staticClass: "mt-1 font-semibold text-xs",
          attrs: { for: _vm.name + "-no" },
        },
        [
          _vm._v(" No "),
          _c("ValidationProvider", { attrs: { rules: _vm.rules } }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.handle,
                  expression: "handle",
                },
              ],
              attrs: { id: _vm.name + "-no", type: "radio", name: _vm.name },
              domProps: { value: 0, checked: _vm._q(_vm.handle, 0) },
              on: {
                change: function ($event) {
                  _vm.handle = 0
                },
              },
            }),
          ]),
        ],
        1
      ),
      _c(
        "label",
        {
          staticClass: "mt-1 ml-3 font-semibold text-xs",
          attrs: { for: _vm.name + "-yes" },
        },
        [
          _vm._v(" Yes "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.handle,
                expression: "handle",
              },
            ],
            attrs: { id: _vm.name + "-yes", type: "radio", name: _vm.name },
            domProps: { value: 1, checked: _vm._q(_vm.handle, 1) },
            on: {
              change: function ($event) {
                _vm.handle = 1
              },
            },
          }),
        ]
      ),
      _vm.extra
        ? _c("label", { staticClass: "mr-2 mt-1 ml-3 font-semibold text-xs" }, [
            _vm._v(" " + _vm._s(_vm.extra.text) + " "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.handle,
                  expression: "handle",
                },
              ],
              attrs: { id: _vm.name, type: "radio", name: _vm.name },
              domProps: {
                value: _vm.extra.value,
                checked: _vm._q(_vm.handle, _vm.extra.value),
              },
              on: {
                change: function ($event) {
                  _vm.handle = _vm.extra.value
                },
              },
            }),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }