var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationObserver", {
    ref: "observer",
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (ref) {
          var invalid = ref.invalid
          return [
            _c(
              "form",
              {
                staticClass: "min-h-full bg-white px-6 pt-6",
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.sendForgotPassword($event)
                  },
                },
              },
              [
                _c("ValidationProvider", {
                  attrs: { rules: "required|email", name: "email" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var errors = ref.errors
                          return [
                            _c("base-label", { attrs: { for: "email" } }, [
                              _vm._v(" Email address "),
                            ]),
                            _c("base-input", {
                              directives: [
                                { name: "focus", rawName: "v-focus" },
                              ],
                              staticClass: "mb-2",
                              attrs: {
                                placeholder: "Enter your email address",
                                name: "email",
                                type: "email",
                                disabled: !_vm.editable,
                              },
                              model: {
                                value: _vm.forgotPasswordEmail,
                                callback: function ($$v) {
                                  _vm.forgotPasswordEmail = $$v
                                },
                                expression: "forgotPasswordEmail",
                              },
                            }),
                            errors.length
                              ? _c(
                                  "p",
                                  { staticClass: "my-2 text-sm text-red-800" },
                                  [_vm._v(" " + _vm._s(errors[0]) + " ")]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
                _c(
                  "div",
                  { staticClass: "mt-4 mb-2 flex w-full justify-end" },
                  [
                    _c(
                      "base-button",
                      {
                        staticClass: "mr-2",
                        attrs: { color: "cancel", type: "button" },
                        nativeOn: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.$emit("cancel")
                          },
                        },
                      },
                      [_vm._v(" Cancel ")]
                    ),
                    _c(
                      "base-button",
                      {
                        attrs: {
                          loading: _vm.loading,
                          color: "primary",
                          disabled: !_vm.forgotPasswordEmail || invalid,
                        },
                      },
                      [_vm._v(" Send reset email ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }