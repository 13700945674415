var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _vm.loading
      ? _c("div", { class: [_vm.baseClasses, _vm.overlayColorClasses] }, [
          _c(
            "div",
            {
              staticClass:
                "inline-flex flex-col space-y-4 items-center justify-center",
              class: [_vm.textColorClasses],
            },
            [
              _c("base-spinner", {
                class: [_vm.sizeClasses],
                attrs: { size: _vm.size },
              }),
              _vm._t("default"),
            ],
            2
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }