var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pt-1 pb-0.5 flex-row space-x-1" },
    [
      _vm.labelText.length
        ? _c("base-label", { attrs: { color: "dark" } }, [
            _vm._v(" " + _vm._s(_vm.labelText) + " "),
          ])
        : _vm._e(),
      !_vm.isInnerContentVisible && _vm.displayValue
        ? _c("span", { staticClass: "text-navy" }, [
            _vm._v(_vm._s(_vm.displayValue)),
          ])
        : _vm._e(),
      _vm.isInnerContentVisible
        ? _c("span", { staticClass: "text-navy" }, [_vm._t("default")], 2)
        : _vm._e(),
      _vm.error
        ? _c("span", { staticClass: "text-red-800 font-semibold" }, [
            _vm._v(_vm._s(_vm.error)),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }